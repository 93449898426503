<template>
  <div class="page-box">
    <base-screen class="custom-screen">
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-row>
          <a-form-model-item label="订单号" prop="orderNo">
            <a-input v-model="queryRef.orderNo" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="订单类型" prop="orderType">
            <a-select v-model="queryRef.orderType" placeholder='请选择' style="width: 120px">
              <a-select-option v-for='item in orderTypes' :key='item.value' :value="item.value">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="商品名称" prop="productName">
            <a-input v-model="queryRef.productName" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="收货人" prop="receiverName">
            <a-input v-model="queryRef.receiverName" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="收货人电话" prop="receiverPhone">
            <a-input v-model="queryRef.receiverPhone" placeholder="请输入" />
          </a-form-model-item>
        </a-row>
        <a-row>
          <a-form-model-item label="下单用户" prop="userName">
            <a-input v-model="queryRef.userName" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item label="下单时间" prop="orderTime">
            <a-range-picker v-model="orderTime" />
          </a-form-model-item>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(0)">
            今
          </a-button>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(1)">
            昨
          </a-button>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(7)">
            近7天
          </a-button>
          <a-button type="text" class="btn-date-shortcut" @click="handleDateShortcut(30)">
            近30天
          </a-button>
        </a-row>
        <a-form-model-item class="screen-btns">
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>

    <base-tabs :tabs="orderStateList" :active="queryRef.orderState" @change="handleStateChange" />
    <div style=''>
      <a-button v-pms='"export"' style='margin-right:32px;' type='primary' :loading='exportLoading' @click='handleExport'>导出订单</a-button>
      <a-upload v-pms='"export"' name="file" accept='.xlsx' :beforeUpload="handleImport" :showUploadList='false'>
        <a-button type='primary' :loading="importLoading">导入订单</a-button>
      </a-upload>
    </div>
    <order-table :columns="columns" :dataSource="dataRef" :pagination="pageRtv" :loading="loadingRef" :orderState="queryRef.orderState" @update="getTableData" @paginationChange="handleTableChange" />
  </div>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import { orderStates, orderTypes } from '@/utils/constants'
import moment from 'moment'
import { order } from '@/api'
import OrderTable from '@/components/OrderTable'
import { getSession } from '@/utils/session'
import { SHOPSTAFFID } from '@/constants'
import { useExport } from '@/utils/tools'
const orderStateList = [
  { title: '全部', value: '' },
  ...orderStates.map((x) => ({ title: x.name, value: x.value })),
  { title: '售后中', value: '-1' },
]

const columns = [
  {
    title: '商品',
    dataIndex: 'name',
  },
  {
    title: '零售价/数量',
    dataIndex: 'price',
  },
  {
    title: '售后',
    dataIndex: 'service',
  },
  {
    title: '买家/收货人',
    dataIndex: 'receiver',
  },
  {
    title: '配送方式',
    dataIndex: 'delivery',
  },
  {
    title: '实收金额(元)',
    dataIndex: 'realAmount',
  },
  {
    title: '订单状态',
    dataIndex: 'orderState',
  },
  {
    title: '操作',
    dataIndex: 'operation',
  },
]

export default {
  name: 'PageGoodsOrder',

  components: {
    OrderTable,
  },

  setup (props, { root }) {
    const formRef = ref(null)
    const orderTime = ref([])
    const { exporting, exportFn } = useExport(() => order.exportOrder(queryRef.value))
    watch(
      () => orderTime.value,
      (data) => {
        if (data.length) {
          queryRef.value.orderBeginTime = `${moment(orderTime.value[0]).format(
            'YYYY-MM-DD'
          )} 00:00:00`
          queryRef.value.orderEndTime = `${moment(orderTime.value[1]).format(
            'YYYY-MM-DD'
          )} 23:59:59`
        } else {
          queryRef.value.orderBeginTime = ''
          queryRef.value.orderEndTime = ''
        }
      }
    )
    const importLoading = ref(false)
    const queryRef = ref({
      orderNo: '',
      productName: '',
      orderState: '',
      orderBeginTime: '',
      orderEndTime: '',
      userName: '',
      receiverName: '',
      receiverPhone: '',
      orderType: undefined,
      shopStaffId: getSession(SHOPSTAFFID),
    })
    onMounted(() => {
      // 售后 6
      // 待发货 1
      queryRef.value.orderState = root.$route.query.state || ''
      getTableData()
    })
    const dataRef = ref([])
    const pageRtv = ref({
      current: 1,
      size: 10,
      total: 0,
    })
    const loadingRef = ref(false)
    async function getTableData () {
      loadingRef.value = true
      dataRef.value = []
      const { data, page } =
        queryRef.value.orderState === '-1'
          ? await order.getGoodsOrderAfter({ ...queryRef.value, ...pageRtv.value })
          : await order.getGoodsOrder({ ...queryRef.value, ...pageRtv.value })
      loadingRef.value = false
      dataRef.value = data || []
      pageRtv.value.total = page.total
    }

    function handleSearch () {
      pageRtv.value.current = 1
      getTableData()
    }

    async function handleReset () {
      formRef.value.resetFields()
      orderTime.value = []
      await root.$nextTick()
      handleSearch()
    }

    function handleTableChange (current) {
      pageRtv.value.current = current
      getTableData()
    }

    function handleStateChange (state) {
      if (state === queryRef.value.orderState) return
      queryRef.value.orderState = state
      handleSearch()
    }

    function handleDateShortcut (day) {
      orderTime.value = [
        moment().subtract(day, 'd'),
        day === 1 ? moment().subtract(1, 'd') : moment(),
      ]
    }
    function handleImport (file, fileList) {
      console.log(111111)
      uploadFile(fileList)
      return false
    }
    async function uploadFile (fileList) {
      const formData = new FormData()
      fileList.forEach((x) => {
        formData.append('file', x)
      })
      importLoading.value = true
      let { code, msg } = await order.importOrder(formData)
      importLoading.value = false
      if (code === '00000') {
        root.$message.success(msg || '导入成功')
        handleSearch()
      } else {
        root.$message.error(msg || '上传失败')
      }
    }

    return {
      orderTypes,
      orderStateList,
      columns,
      formRef,
      orderTime,
      queryRef,
      dataRef,
      pageRtv,
      loadingRef,
      getTableData,
      handleSearch,
      handleReset,
      handleStateChange,
      handleTableChange,
      handleDateShortcut,
      exportLoading: exporting,
      handleExport: exportFn,
      handleImport,
      importLoading,
    }
  },
}
</script>

<style lang="less" scoped>
@label-width: 84px;
.custom-screen {
  ::v-deep .solt-div {
    padding: 10px 0 0;
  }
  ::v-deep .ant-form-item-label {
    width: @label-width;
  }
}
.screen-btns {
  margin-left: @label-width;
}
.btn-date-shortcut {
  margin: 4px 12px 0 0;
  &:last-child {
    margin-right: 0;
  }
}
</style>
